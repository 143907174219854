import emotion from '@emotion/styled';

const opacity = ({ show }) => ({ opacity: show ? 1 : 0 });

export const Wrap = emotion.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
  // overflow: 'hidden'
});

export const Placeholder = emotion.div(
  {
    borderRadius: '100%',
    filter: `blur(8px)`,
    transition: 'opacity 300ms linear'
  },
  opacity,
  ({ backgroundColor, ratio, styles = {} }) => ({
    backgroundColor,
    ...(ratio && { paddingBottom: `${100 / ratio}%` }),
    ...styles
  })
);
