import emotion from '@emotion/styled';
import GraphCmsImage from 'components/GraphCmsImage';

export const ImageDialog = emotion.div({
  width: '100%'
});

export const Image = emotion(GraphCmsImage)({
  zIndex: 999,
  width: '100%',
  maxWidth: '800px',
  boxShadow: `0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4)`
});
