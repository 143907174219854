import emotion from '@emotion/styled';
import { cardShadow, unlink } from 'styles/mixins';
import flex from 'styles/flex';
import colors from 'styles/colors';
import { queries } from 'styles/responsive';
import { Link } from '@reach/router';

//components
import GraphCmsImage from 'components/GraphCmsImage';
import fontWeights from 'config/fontWeights';

//classnames
export const workshopListingClassName = 'workshop-listing';
export const bottomClassName = `${workshopListingClassName}__bottom`;

const BOTTOM_HEIGHT = 50;

export const WorkshopListing = emotion.div(
  {
    ...flex.vertical,
    minHeight: 300 + BOTTOM_HEIGHT,
    transition: 'all 100ms linear',
    color: colors.textColor,
    flex: 1,
    maxWidth: 450,
    [queries.mediumWidth]: {
      minHeight: 300
    }
  },
  p => ({
    ...(p.available && {
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.01)'
      }
    })
  })
);

export const Top = emotion.div({
  ...flex.vertical,
  ...flex.centerVerticalH,
  ...cardShadow,
  ...unlink,
  padding: 25,
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  flex: 1
});

export const Date = emotion.div({
  fontWeight: fontWeights.SIX
});

export const Location = emotion.div({});

export const Title = emotion.div({
  fontSize: 28,
  fontWeight: fontWeights.FOUR,
  lineHeight: '31px',
  textAlign: 'center'
});

export const Logo = emotion(GraphCmsImage)({
  maxHeight: 70,
  maxWidth: 140,
  objectFit: 'contain',
  margin: 'auto'
});

export const Bottom = emotion.div(
  {
    ...flex.horizontal,
    ...flex.centerHorizontal,
    height: BOTTOM_HEIGHT,
    // opacity: 0,
    // transform: 'translateY(-70px)',
    zIndex: -1,
    transition: 'all 150ms linear',
    ...cardShadow,
    backgroundColor: '#f5f5f5',
    color: '#6f6f6f'
  },
  p => ({
    ...(p.available && {
      backgroundColor: colors.buttonColor,
      color: 'white'
    })
  })
);

export const LocationAndDate = emotion.div({
  ...flex.vertical,
  ...flex.centerVerticalH,
  fontSize: 18,
  textAlign: 'center',
  lineHeight: '25px',
  marginBottom: 5
});

export const LinkInside = emotion(Link)({
  ...unlink,
  color: colors.textColor
});
