import React, { Component } from 'react';

//glamorous
import { PopupDialog, Content, Overlay } from './styles';

//external
import BodyClassName from 'react-body-classname';

class PopupDialogComponent extends Component {
  componentDidMount() {
    document.body.parentElement.addEventListener(
      'touchmove',
      this.preventScroll,
      { passive: false }
    );
  }

  componentWillUnmount() {
    document.body.parentElement.removeEventListener(
      'touchmove',
      this.preventScroll,
      { passive: false }
    );
  }

  preventScroll = e => {
    e.preventDefault();
    return false;
  };

  render() {
    const { close, children } = this.props;

    return (
      <PopupDialog onWheel={this.preventScroll} onScroll={this.preventScroll}>
        <BodyClassName className="dialog-open" />
        <Overlay onClick={close} />
        <Content>{children}</Content>
      </PopupDialog>
    );
  }
}

export default PopupDialogComponent;
