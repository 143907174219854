import React from 'react';
import { Wrap, Placeholder } from './styles';

//components
import Fade from 'components/Fade';

class LazySvg extends React.Component {
  state = { loaded: false };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;

    const {
      src,
      inView,
      placeholderStyles,
      className,
      backgroundColor,
      showPlaceholder = true,
      duration = 200,
      passStylesToImage = true
    } = this.props;

    return (
      <Wrap className={className}>
        {showPlaceholder && (
          <Placeholder
            backgroundColor={backgroundColor}
            show={!loaded}
            className={className}
            styles={placeholderStyles}
          />
        )}

        <Fade duration={duration} in={loaded}>
          {inView && (
            <img
              {...passStylesToImage && { className }}
              onLoad={this.onLoad}
              src={src}
            />
          )}
        </Fade>
      </Wrap>
    );
  }
}

export default LazySvg;
