import React, { Component, Fragment } from 'react';

//glamorous
import {
  WorkshopListing,
  Top,
  LocationAndDate,
  Bottom,
  Logo,
  Title,
  Location,
  Date,
  LinkInside,
  workshopListingClassName,
  bottomClassName
} from './styles';

import ConditionalWrap from 'components/ConditionalWrap';
import { getWorkshopDate } from 'utils/date-utils';

class WorkshopListingComponent extends Component {
  render() {
    const { workshop, inView } = this.props;

    if (!workshop) {
      return null;
    }

    const {
      title: workshopTitle,
      country,
      city,
      dateStart,
      dateEnd,
      flag,
      slug,
      logo,
      isPrivate,
      comingSoon,
      allowFrontpageClick,
      dateNotAnnounced,
      workshopType: { title: typeTitle }
    } = workshop;


    const title = workshopTitle || typeTitle;
    const showDate = dateStart !== undefined && dateNotAnnounced !== true;
    const dateRange = getWorkshopDate(dateStart, dateEnd);

    return (
      <ConditionalWrap
        condition={allowFrontpageClick}
        wrap={child => (
          <LinkInside to={`/workshop/${slug}`}>{child}</LinkInside>
        )}
      >
        <WorkshopListing
          available={allowFrontpageClick}
          className={workshopListingClassName}
        >
          <Top>
            <Title>{title}</Title>
            <Logo inView={inView} logo={logo} />

            <LocationAndDate>
              <Location>
                {flag}
                {city && <Fragment>{city}, </Fragment>}
                {country}
              </Location>
              <Date>{showDate ? dateRange : 'Coming soon'}</Date>
            </LocationAndDate>
          </Top>

          <Bottom available={allowFrontpageClick} className={bottomClassName}>
            {do {
              if (isPrivate) {
                ('Private company training');
              } else if (comingSoon) {
                ('Tickets Coming Soon');
              } else {
                ('Register');
              }
            }}
          </Bottom>
        </WorkshopListing>
      </ConditionalWrap>
    );
  }
}

export default WorkshopListingComponent;
