import emotion from '@emotion/styled';
import { queries } from 'styles/responsive';

import { Section, Wrapper as $Wrapper } from 'styles/shared-components';

export const LearnReactSection = emotion(Section)({});

export const Wrapper = emotion($Wrapper)({
  padding: '100px 50px 0 50px',
  [queries.smallWidth]: {
    padding: '50px 30px 0 30px'
  },
  [queries.to.smallest]: {
    padding: '50px 20px'
  }
});
