export const cycleValue = (current, max) => {
  current++;
  if (current > max) {
    current = 0;
  }
  return current;
};

export const cycleValueAround = (current, change, max) => {
  current = current + change;
  if (current >= max) {
    current = 0;
  } else if (current === -1) {
    current = max - 1;
  }
  return current;
};
