import React from 'react';
import { Transition } from 'react-transition-group';

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 }
};

const Fade = ({ in: inProp, children, duration = 200 }) => (
  <Transition in={inProp} timeout={duration}>
    {state => (
      <div
        style={{
          transition: `opacity ${duration}ms ease-in-out`,
          opacity: 0,
          ...transitionStyles[state]
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

export default Fade;
