import React, { Component } from "react";

//glamorous
import * as S from "./styles";

//images
import reactImage from "images/tech-logos/react.svg";

import LazyLoad from "components/LazyLoader";

class TechInfoCard extends Component {
  render() {
    const { className, ref, style, description, points = [] } = this.props;

    return (
      <S.ReactModule
        innerRef={ref}
        ref={ref}
        id="react-moodule"
        style={style}
        className={className}
      >
        <S.LeftSection>
          <LazyLoad height={140}>
            <S.Logo src={reactImage} />
          </LazyLoad>
          <S.Text>{description}</S.Text>
        </S.LeftSection>
        <S.RightSection>
          <S.List>
            {points.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </S.List>
        </S.RightSection>
      </S.ReactModule>
    );
  }
}

export default TechInfoCard;
