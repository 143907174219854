import emotion from '@emotion/styled';
import { queries } from 'styles/responsive';
import { grid } from 'styles/mixins';
import flex from 'styles/flex';

//components
import { Title as $Title } from 'styles/shared-components';

export const UpcomingWorkshopsSection = emotion.div({
  position: 'relative'
});

export const Title = emotion($Title)({
  marginBottom: 100
});

const splitAt = 3;
const spaceBetween = 50;

export const WorkshopList = emotion.div(
  {
    marginBottom: 100
  },
  ({ gridSize, num }) => ({
    ...(num >= splitAt &&
      grid(gridSize, spaceBetween, spaceBetween, {
        fallbackParent: {
          ...flex.horizontal,
          ...flex.centerHorizontalV
        },
        fallbackChild: {
          margin: '0 30px'
        }
      })),
    ...(num < splitAt && {
      ...flex.horizontal,
      ...flex.centerHorizontal,
      ...flex.wrap,
      margin: 'auto auto 100px auto',
      '& > div': {
        marginRight: spaceBetween,
        [queries.smallWidth]: {
          marginBottom: 25,
          marginRight: 0
        }
      }
    })
  }),
  {
    [queries.largeWidth]: {
      ...grid(2, 40)
    },
    [queries.mediumWidth]: {
      ...grid(1, 0, 25)
    }
  }
);

export const Wrapper = emotion.div({
  maxWidth: 1200,
  padding: `100px 50px`,
  margin: 'auto',
  position: 'relative',
  width: '100%',
  zIndex: splitAt,
  [queries.smallWidth]: {
    padding: '50px 25px'
  }
});
