import emotion from '@emotion/styled';
import flex from 'styles/flex';

export const PopupDialog = emotion.div({
  ...flex.horizontal,
  ...flex.centerHorizontal,
  position: 'fixed',
  zIndex: 9999999999,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
});

export const Overlay = emotion.div(flex.horizontal, flex.centerHorizontal, {
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  margin: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 0
});

export const Content = emotion.div({
  margin: 20,
  width: '100%',
  ...flex.vertical,
  ...flex.centerVertical
});
