import React, { Component } from 'react';

//glamorous
import { MapOfWorldBackground, BackgroundTop, MapImage } from './styles';

const mapSvg = require('images/map2.svg');

class MapOfWorldBackgroundComponent extends Component {
  render() {
    const { inView, className } = this.props;

    return (
      <MapOfWorldBackground className={className}>
        <MapImage
          duration={1000}
          inView={inView}
          showPlaceholder={false}
          src={mapSvg}
          passStylesToImage={false}
        />
        <BackgroundTop />
      </MapOfWorldBackground>
    );
  }
}

export default MapOfWorldBackgroundComponent;
