import emotion from '@emotion/styled';
import { size } from 'styles/mixins';
import flex from 'styles/flex';
import colors from 'styles/colors';
import { queries } from 'styles/responsive';
import LazySvg from 'components/LazySvg';
import fontWeights from 'config/fontWeights';

/* Responsive
* Variant 1: Enough space between them so the title can be only 1 line
* Variant 2: Not enough space between them, title cannot take 1 line
* Variant 3: One below another
* */

export const WorkshopType = emotion.div({
  width: '100%'
});

export const Title = emotion.div({
  fontSize: 28,
  color: colors.titleColor,
  lineHeight: '30px',
  margin: '35px 0',
  fontWeight: 'bold',
  [queries.largerWidth]: {
    margin: '20px 0',
    fontSize: 25,
    textAlign: 'center'
  }
});

export const Description = emotion.div({
  fontSize: 17,
  lineHeight: '26px',
  fontWeight: fontWeights.FOUR,
  color: '#6b7c93',
  textAlign: 'center'
});

export const Icon = emotion(LazySvg)({
  ...size(90),
  [queries.to.ipad]: {
    ...size(80)
  }
});

export const Top = emotion.div({
  ...flex.vertical,
  ...flex.centerVerticalH
});
