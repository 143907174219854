import React, { Component } from 'react';

//glamorous
import { WorkshopType, Top, Icon, Title, Description } from './styles';

class WorkshopTypeComponent extends Component {
  render() {
    const {
      title,
      style,
      icon,
      inView,
      iconBackgroundColor,
      className,
      description
    } = this.props;
    return (
      <WorkshopType style={style} className={className}>
        <Top>
          <Icon
            backgroundColor={iconBackgroundColor}
            inView={inView}
            src={icon}
          />
          <Title>{title}</Title>
        </Top>
        <Description>{description}</Description>
      </WorkshopType>
    );
  }
}

export default WorkshopTypeComponent;
