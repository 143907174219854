import emotion from '@emotion/styled';

export const Arrow = emotion.div(
  {
    transition: 'opacity 300ms linear'
  },
  p => ({
    ...(!p.show && {
      opacity: 0
    })
  })
);

export default `
  .arrow {
    padding-top: 70px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    z-index: 2;
    transform: translate(0, -50%);
    color: #fff;
    font : normal 400 20px/1 'Josefin Sans', sans-serif;
    letter-spacing: .1em;
    text-decoration: none;
    transition: opacity .3s;
  }
  
  .arrow span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: rotate(-45deg);
    animation: sdb05 1.5s infinite;
    box-sizing: border-box;
  }
  
  @keyframes sdb05 {
    0% {
      transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: rotate(-45deg) translate(-20px, 20px);
      opacity: 0;
    }
  }
`;
