import React, { Component } from "react";

//glamorous
import { Title, Subtitle } from "styles/shared-components";
import { LearnReactSection, Wrapper } from "./styles";

//components
import Background from "components/Background";

import { sections } from "styles/config";
const { labs } = sections;

//animation
import { animations } from "styles/animations";
import { AnimateIn } from "react-genie";
import TechInfoCard from "components/TechInfoCard";

class LearnReactSectionComponent extends Component {
  static defaultProps = {
    workshopType: {
      sectionTitle: "Learn React the right way",
      sectionSubtitle: `It's easy to get lost without proper guidance and path to follow.`
    }
  };

  render() {
    const { workshopType, disableBackground } = this.props;

    const {
      sectionTitle: title,
      sectionSubtitle: subtitle,
      sectionDescription: description,
      points
    } = workshopType;

    return (
      <LearnReactSection {...labs.section}>
        <Background
          custom={{ position: "-80px" }}
          height="60%"
          {...!disableBackground && labs.background}
        />
        <Wrapper>
          <Title {...labs.title} centered>
            {title}
          </Title>

          <Subtitle {...labs.subtitle}>{subtitle}</Subtitle>

          <TechInfoCard
            description={`React may be just a simple UI library, but it involves a pretty big
                                ecosystem around it. Explaining the basics and internals is not
                                enough, so in this module we're going to explain the best practices,
            we're going to learn to "think in React", and apply solutions to real problems
                                that you might encounter in the process of building an app.`}
            points={[
              "Fundamental concepts",
              "Virtual DOM",
              "Stateless components",
              "JSX",
              "Props",
              "Stateful components",
              "State management",
              "Events",
              "Lifecycle methods"
            ]}
          />
        </Wrapper>
      </LearnReactSection>
    );
  }
}

export default LearnReactSectionComponent;
