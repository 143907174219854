import React, { Component } from 'react';
//glamorous
import {
  Title,
  UpcomingWorkshopsSection,
  WorkshopList,
  Wrapper
} from './styles';

import isBefore from 'date-fns/is_before';

//lodash
import orderBy from 'lodash/orderBy';

//components
import WorkshopListing from 'components/WorkshopListing';
import WorkshopEmailSubscribe from 'components/WorkshopEmailSubscribe';
import MapOfWorldBackgroundComponent from 'components/MapOfWorldBackground';
import { Observer } from 'react-genie';

class UpcomingWorkshopsSectionComponent extends Component {
  render() {
    const { workshops: initialWorkshops } = this.props;

    const workshops = initialWorkshops.filter(
      w => !isBefore(w.dateEnd || w.dateStart, new Date())
    );

    const gridSize = do {
      if (workshops.length === 2) {
        2;
      }
      if (workshops.length === 4) {
        4;
      }
      3;
    };

    const allWorkshops = orderBy(workshops, workshop => workshop.dateStart);

    return (
      <Observer threshold={0.1} triggerOnce={true}>
        {({inView, ref}) => (
          <UpcomingWorkshopsSection ref={ref}>
            <MapOfWorldBackgroundComponent inView={inView} />
            <Wrapper>
              <Title centered> Upcoming workshops </Title>
              <WorkshopList
                className="WORKSHOP-LIST"
                num={allWorkshops.length}
                gridSize={gridSize}
              >
                {allWorkshops.map(workshop => (
                  <WorkshopListing
                    inView={inView}
                    workshop={workshop}
                    key={workshop.slug}
                  />
                ))}
              </WorkshopList>
              <WorkshopEmailSubscribe />
            </Wrapper>
          </UpcomingWorkshopsSection>
        )}
      </Observer>
    );
  }
}

export default UpcomingWorkshopsSectionComponent;
