import emotion from '@emotion/styled';
import LazySvg from 'components/LazySvg';

export const MapOfWorldBackground = emotion.div({
  top: 0,
  position: 'absolute',
  width: '100%',
  overflow: 'hidden',
  maxWidth: '100vw'
});

export const BackgroundTop = emotion.div({
  position: 'absolute',
  top: 0,
  height: 150,
  zIndex: 0,
  width: '100%',
  background: 'linear-gradient(0deg,rgba(255, 255, 255, 0),#ffffff 50%)'
});

export const MapImage = emotion(LazySvg)({
  objectFit: 'cover',
  opacity: '0.07',
  width: '100%',
  height: '100%',
  maxWidth: '100vw'
});
