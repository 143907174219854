import emotion from '@emotion/styled';
import colors from 'styles/colors';
import { Button as $Button } from 'styles/shared-components';
import flex from 'styles/flex';
import { unstyleButton, animate, padding } from 'styles/mixins';
import CopyToClipboard from 'react-copy-to-clipboard';

export const ContactLink = emotion.div({
  ...flex.horizontal,
  margin: 'auto'
});

export const LinkButton = emotion($Button)(p => ({
  ...(p.disableHover && {
    '&:hover': {
      backgroundColor: 'white',
      cursor: 'text',
      color: 'black'
    }
  })
}));

export const Link = LinkButton.withComponent('a');

export const Buttons = emotion.div({
  ...flex.vertical
});

export const Button = emotion.div({
  ...flex.vertical,
  ...flex.centerVertical,
  ...unstyleButton,
  ...animate(120),
  ...padding.horizontal(15),
  flex: 1,
  border: '3px solid #356182',
  cursor: 'pointer',
  position: 'relative',
  left: -3,
  fontSize: 13,
  minWidth: 72,
  '> a': {
    textDecoration: 'none',
    color: 'black'
  },
  ':first-child': {
    borderBottom: 0
  },
  ':hover': {
    backgroundColor: '#356182',
    color: 'white',
    '> a': {
      color: 'white'
    }
  }
});

export const CopyButton = Button.withComponent(CopyToClipboard);
