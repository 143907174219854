import React, { Component } from 'react';
import { onMount, onUnmount } from 'react-keydown/dist/event_handlers';

import { setBinding } from 'react-keydown/dist/store';

//glamorous
import { ImageDialog, Image } from './styles';

//components
import PopupDialog from 'components/PopupDialog';

class ImageDialogComponent extends Component {
  state = {
    openedImage: null
  };

  componentDidMount() {
    onMount.call(this);
    this.changeImage(this.props);
  }

  componentWillUnmount() {
    onUnmount.call(this);
  }

  submit(e) {
    this.props.onGalleryKeyPress(e);
  }

  componentWillReceiveProps(nextProps) {
    this.changeImage(nextProps);
  }

  changeImage = props => {
    this.setState({ openedImage: null }, () => {
      setTimeout(() => {
        this.setState({ openedImage: props.openedImage });
      }, 0);
    });
  };

  render() {
    const { openedImage } = this.state;
    const { closeOpenedImage } = this.props;

    return (
      <ImageDialog>
        <PopupDialog close={closeOpenedImage}>
          {openedImage && (
            <Image
              blurAmount={1}
              tempBlur={false}
              resizeConfig={{ w: 860 }}
              inView={true}
              logo={openedImage}
            />
          )}
        </PopupDialog>
      </ImageDialog>
    );
  }
}

setBinding({
  target: ImageDialogComponent.prototype,
  fn: ImageDialogComponent.prototype.submit,
  keys: ['left', 'right', 'esc', 'space', 'up', 'down']
});

export default ImageDialogComponent;
