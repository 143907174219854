import React, { Component } from 'react';

//glamorous
import { GetAQuoteSection, Centered, Wrapper } from './styles';
import { Title, Subtitle } from 'styles/shared-components';

//components
import ContactLink from 'components/ContactLink';

class GetAQuoteSectionComponent extends Component {
  render() {
    return (
      <GetAQuoteSection withSpace>
        <Wrapper>
          <Title centered> Get in touch </Title>
          <Subtitle>
            Interested in a workshop or company training? Click the contact link
            below and we can discuss! <br />
          </Subtitle>
          <Centered>
            <ContactLink />
          </Centered>
        </Wrapper>
      </GetAQuoteSection>
    );
  }
}

export default GetAQuoteSectionComponent;
