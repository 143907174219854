import React, { Component } from 'react';

//glamorous
import { QuoteSection, Wrapper } from './styles';
import { Quote } from 'styles/shared-components';

class QuoteSectionComponent extends Component {
  render() {
    return (
      <QuoteSection>
        <Wrapper>
          <Quote>
            “React is such a good idea that we will spend the rest of the decade
            continuing to explore its implications and applications” - @rauchg
          </Quote>
        </Wrapper>
      </QuoteSection>
    );
  }
}

export default QuoteSectionComponent;
