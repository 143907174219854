import React, { Component } from 'react';

//glamorous
import { TopicsSection, TopicBlocks, Wrapper } from './styles';
import { Title, Subtitle } from 'styles/shared-components';

//components
import Background from 'components/Background';
import Sprinkles from 'components/Sprinkles';
import TopicBlock from 'components/TopicBlock';

import { sections } from 'styles/config';
const { topics: sectionConfig } = sections;

//animation
import { AnimateIn } from 'react-genie';
import { animations } from 'styles/animations';

class TopicsSectionComponent extends Component {
  render() {
    const { topics, displayKeys } = this.props;
    const filteredTopics = displayKeys.map(topicKey =>
      topics.find(topic => topic.slug === topicKey)
    );

    return (
      <TopicsSection {...sectionConfig.section}>
        <Background {...sectionConfig.background} />
        {sectionConfig.sprinkles && (
          <Sprinkles id="topics-section" {...sectionConfig.sprinkles} />
        )}

        <Wrapper>
          <Title centered {...sectionConfig.title}>
            For beginners and professionals
          </Title>
          <Subtitle {...sectionConfig.subtitle}>
            Choose from a variety of topics and tailor the workshop in any way
            you want. Any combination is possible!
          </Subtitle>

          <AnimateIn
            animationName={animations.fadeIn}
            hasWrapper={true}
            delayBetween={200}
          >
            <TopicBlocks>
              {filteredTopics.map(topic => (
                <TopicBlock key={topic.id} topic={topic} />
              ))}
            </TopicBlocks>
          </AnimateIn>
        </Wrapper>
      </TopicsSection>
    );
  }
}

export default TopicsSectionComponent;
