import React, { Component } from "react";
import sortBy from "lodash/sortBy";

import socialImageDefault from "config/default-social-image";

//styles
import * as S from "styles/index-styles";

//sections
import MainSection from "components/MainSection";
import StudentsSection from "components/StudentsSection";
import TrainerSection from "components/TrainerSection";
import WorkshopSection from "components/WorkshopSection";
import WorkshopTypesSection from "components/WorkshopTypesSection";
import TopicsSection from "components/TopicsSection";
import GallerySection from "components/GallerySection";
import LabsSection from "components/LabsSection";
import LearnReactSection from "components/LearnReactSection";
import TryLabsSection from "components/TryLabsSection";
import QuoteSection from "components/QuoteSection";
import GetAQuoteSection from "components/GetAQuoteSection";
import FooterSection from "components/FooterSection";
import UpcomingWorkshopsSection from "components/UpcomingWorkshopsSection";

//components
import Page from "components/Page";
import Layout from "components/Layout";

class Home extends Component {
  render() {
    const { pageContext } = this.props;

    if (!pageContext) {
      return null;
    }

    const {
      allWorkshops: workshops,
      allTopics: topics,
      allCompanies: companies,
      allConferences: conferences,
      allSettingses: settings,
      allGalleryImages
    } = pageContext;

    const galleryImages = allGalleryImages.map(i => i.image);
    const defaultSettings = settings[0];
    const allClients = [...companies, ...conferences];
    const clients = sortBy(allClients, client => client.priority);

    return (
      <Layout>
        <Page
          pageTitle="React Academy"
          title="React Academy"
          description="Interactive React and JavaScript workshop for public training, private company training, or conference workshops. The first workshop accompanied by an interactive web app for practical exercises."
          url="https://reactacademy.io"
          imageUrl={socialImageDefault}
        >
          <S.Home>
            <MainSection settings={defaultSettings} />
            <UpcomingWorkshopsSection workshops={workshops} />
            <WorkshopTypesSection />
            <WorkshopSection />
            <LabsSection />
            <TryLabsSection />
            <StudentsSection clients={clients} />
            <GallerySection images={galleryImages} />
            <LearnReactSection />
            <QuoteSection />
            <TopicsSection
              displayKeys={[
                "es2015",
                "tools",
                "react",
                "advanced-patterns",
                "react-16-beyond",
                "state-management",
                "graphql",
                "css-in-js",
                "styling"
              ]}
              topics={topics}
            />
            <TrainerSection instructor={defaultSettings.instructor} />
            <GetAQuoteSection />
            <FooterSection />
          </S.Home>
        </Page>
      </Layout>
    );
  }
}

export default Home;
