import emotion from '@emotion/styled';
import {
  Section,
  Wrapper as $Wrapper,
  Button as $Button
} from 'styles/shared-components';

import flex from 'styles/flex';

export const GetAQuoteSection = emotion(Section)({});

export const Wrapper = emotion($Wrapper)({
  ...flex.vertical,
  ...flex.centerVerticalV
});

export const Button = emotion($Button)({
  width: 150,
  margin: 'auto'
});

export const Centered = emotion.div({
  ...flex.horizontal,
  ...flex.centerHorizontal,
  width: '100%'
});

export const LinkButton = Button.withComponent('a');
