import React from 'react';
import emotion from '@emotion/styled';
import { Section } from 'styles/shared-components';
import { queries } from 'styles/responsive';
import { smallWrapperStyles } from 'styles/shared-components';
import $Background from 'components/Background';

import flex from 'styles/flex';
import { fadeInAnimation } from 'styles/animations';

//images
import GraphCmsImage from 'components/GraphCmsImage';

import { createZIndexGetter, underParent } from 'styles/mixins';
import { ANIMATION_STEPS } from 'config/animation-steps';

const ELEMENTS = {
  BACKGROUND_IMAGE: 'backgroundImage',
  GRADIENT: 'gradient',
  CONTENT: 'CONTENT',
  OVERLAY: 'overlay'
};

const getZIndex = createZIndexGetter([
  ELEMENTS.OVERLAY,
  ELEMENTS.BACKGROUND_IMAGE,
  ELEMENTS.GRADIENT,
  ELEMENTS.CONTENT
]);

export const MainSection = emotion(Section)(
  {
    ...flex.vertical,
    position: 'relative',
    flex: 1,
    minHeight: '100vh',
    width: '100%',
    overflow: 'hidden'
  },
  p => ({
    ...(p.collapsed && {
      minHeight: 'auto',
      height: 55
    })
  })
);

export const GradientOverlay = emotion($Background)({
  ...getZIndex(ELEMENTS.GRADIENT),
  animation: `${fadeInAnimation(0.7)} 500ms forwards ease-in-out`
});

export const LazyBackground = emotion(GraphCmsImage)({
  width: '100vw',
  height: '100vh',
  objectFit: 'cover',
  filter: `grayscale(1)`,
  ...getZIndex(ELEMENTS.BACKGROUND_IMAGE)
});

export const Content = emotion.div(
  {
    opacity: 0,
    ...getZIndex(ELEMENTS.CONTENT),
    ...flex.vertical,
    ...flex.centerVertical,
    flex: 1,
    [queries.smallWidth]: {
      ...smallWrapperStyles
    }
  },
  underParent(ANIMATION_STEPS.ANIMATE_CONTENT, {
    animation: `${fadeInAnimation()} 700ms forwards ease-in-out`
  })
);

export const Overlay = emotion.div({
  ...getZIndex(ELEMENTS.OVERLAY),
  position: 'absolute',
  width: '100%',
  height: '100%'
});

export const Subtitle = emotion.div(
  {
    fontSize: 28,
    fontWeight: 400,
    color: 'white',
    textShadow: `1px 1px 5px rgba(0, 0, 0, 0.4)`,
    textAlign: 'center',
    height: 28,
    opacity: 0,
    [queries.mediumWidth]: {
      fontSize: 23
    },
    [queries.smallWidth]: {
      fontSize: 20
    }
  },
  underParent(ANIMATION_STEPS.ANIMATE_BRACKETS, {
    animation: `${fadeInAnimation()} 500ms forwards ease-in-out`
  })
);
