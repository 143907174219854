import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";

//config
import { cycleValueAround } from "utils/number-utils";

//glamorous
import { GallerySection, Box, Images, Image, Wrapper } from "./styles";
import { queries } from "styles/responsive";

//components
import ImageDialog from "components/ImageDialog";
import Fade from "components/Fade";

//animation
import { Observer, Enter } from "react-genie";
import { animations } from "styles/animations";

class GallerySectionComponent extends Component {
  state = {
    openedImage: null,
    isMobile: false
  };

  cycleImage = change => {
    const currentIndex = this.props.images.findIndex(
      i => i.handle === this.state.openedImage.handle
    );
    const newIndex = cycleValueAround(
      currentIndex,
      change,
      this.props.images.length
    );

    const openedImage = this.props.images[newIndex];
    this.setState({ openedImage });
  };

  galleryKeyMap = {
    Escape: () => this.setState({ openedImage: null }),
    ArrowRight: () => this.cycleImage(1),
    ArrowLeft: () => this.cycleImage(-1),
    Space: e => e.preventDefault(),
    ArrowUp: e => e.preventDefault(),
    ArrowDown: e => e.preventDefault()
  };

  onGalleryKeyPress = e => {
    const { key, code } = e;

    const hasKey = key && key.trim() !== "";
    const keycode = hasKey ? key : code;
    let foundEvent = this.galleryKeyMap[keycode];
    if (foundEvent) {
      foundEvent(e);
    }
  };

  closeOpenedImage = () => {
    this.setState({ openedImage: null });
  };

  componentDidMount = () => {
    this.setState({
      isMobile: !window.matchMedia(queries.aboveMedium).matches,
      innerWidth: window.innerWidth,
      modalElement: document.getElementById("___modal")
    });
  };

  clickImage = image => {
    if (!this.state.isMobile) {
      this.setState({ openedImage: image });
    }
  };

  render() {
    const { openedImage, isMobile, innerWidth, modalElement } = this.state;
    const { images = [] } = this.props;
    const hasOpenedImage = openedImage !== null && openedImage !== undefined;

    return (
      <Fragment>
        <GallerySection isMobile={isMobile}>
          <Observer triggerOnce={true}>
            {({ inView, ref }) => (
              <Wrapper ref={ref}>
                <Images>
                  {images.map((image, index) => {
                    const mobileRatio = Math.ceil(
                      (image.height * innerWidth) / image.width
                    );

                    return (
                      <Enter
                        key={image.handle}
                        visible={inView}
                        delay={200 * index}
                        animationName={animations.fadeIn}
                      >
                        <Box isMobile={isMobile}>
                          <Image
                            ratioPlaceholder={false}
                            blurAmount={1}
                            onClick={() => this.clickImage(image)}
                            tempBlur={false}
                            resizeConfig={{
                              w: isMobile ? innerWidth : 260,
                              h: isMobile ? mobileRatio : 220,
                              fit: "crop"
                            }}
                            inView={inView}
                            logo={image}
                          />
                        </Box>
                      </Enter>
                    );
                  })}
                </Images>
              </Wrapper>
            )}
          </Observer>
        </GallerySection>

        {modalElement &&
          ReactDOM.createPortal(
            <Fade in={hasOpenedImage}>
              {hasOpenedImage && (
                <ImageDialog
                  closeOpenedImage={this.closeOpenedImage}
                  openedImage={openedImage}
                  onGalleryKeyPress={this.onGalleryKeyPress}
                />
              )}
            </Fade>,
            modalElement
          )}
      </Fragment>
    );
  }
}

export default GallerySectionComponent;
