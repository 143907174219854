import emotion from '@emotion/styled';
import { Wrapper as $Wrapper } from 'styles/shared-components';
import { queries } from 'styles/responsive';

export const QuoteSection = emotion.div({});

export const Wrapper = emotion($Wrapper)({
  [queries.mediumWidth]: {
    paddingTop: 30,
    paddingBottom: 30
  }
});
