import emotion from '@emotion/styled';
import flex from 'styles/flex';
import { Section, Grid } from 'styles/shared-components';

export const WorkshopTypesSection = emotion(Section)(
  {
    ...flex.vertical,
    ...flex.centerVerticalH
  },
  {
    minHeight: 600,
    zIndex: 99999,
    position: 'relative'
  }
);

export const WorkshopTypes = emotion(Grid)({});
