import React, { Component } from 'react';

//glamorous
import { ContactLink, Link, Buttons, Button, CopyButton } from './styles';
import { queries } from 'styles/responsive';

import { EMAIL } from 'config/constants';
import { contact } from 'config/props';

class ContactLinkComponent extends Component {
  state = {
    showEmail: false,
    copied: false,
    isMobile: false
  };

  show = () => {
    this.setState({ showEmail: true });
  };

  copy = () => {
    this.setState({ copied: true });
  };

  componentDidMount() {
    let isMobile = window.matchMedia(queries.smallWidth).matches;
    this.setState({ isMobile });
  }

  render() {
    const { isMobile, showEmail, copied } = this.state;

    return (
      <ContactLink onClick={!isMobile && this.show}>
        <Link {...isMobile && contact} disableHover={showEmail || isMobile}>
          {showEmail ? EMAIL : 'Contact'}
        </Link>
        {showEmail && (
          <Buttons>
            <CopyButton onCopy={this.copy} text={EMAIL}>
              <span>{copied ? 'copied' : 'copy'}</span>
            </CopyButton>
            <Button>
              <a {...contact}>open</a>
            </Button>
          </Buttons>
        )}
      </ContactLink>
    );
  }
}

export default ContactLinkComponent;
