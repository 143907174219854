import emotion from '@emotion/styled';
import { grid } from 'styles/mixins';
import { queries, breakpoints as b, toWidth } from 'styles/responsive';
import flex from 'styles/flex';
import { Wrapper as $Wrapper } from 'styles/shared-components';
import GraphCmsImage from 'components/GraphCmsImage';

export const GallerySection = emotion.div(
  {
    position: 'relative'
  },
  ({ isMobile }) => ({
    ...(!isMobile && {
      transform: 'skewY(-5deg)',
      paddingTop: 120,
      paddingBottom: 120,
      [queries.mediumWidth]: {
        paddingTop: 0,
        paddingBottom: 0
      }
    }),
    ...(isMobile && {
      paddingTop: 25,
      paddingBottom: 25
    })
  })
);

export const Wrapper = emotion($Wrapper)({
  [queries.mediumWidth]: {
    padding: 0
  }
});

export const Images = emotion.div({
  ...grid(4, 20, undefined, {
    fallbackParent: {
      ...flex.horizontal,
      ...flex.wrap
    },
    fallbackChild: {
      flex: '1 0 400px',
      ...flex.vertical,
      maxHeight: 300,
      overflow: 'hidden',
      ':nth-child(2)': {
        display: 'none'
      },
      '& img': {
        height: '100%',
        width: '100%',
        flex: 1
      }
    }
  }),
  [toWidth(b.ipad - 1)]: {
    ...grid(3)
  },
  [queries.largeWidth]: {
    ...grid(2, 35)
  },
  [queries.mediumWidth]: {
    padding: 20,
    ...grid(2, 20)
  },
  [toWidth(375)]: {
    padding: 20,
    ...grid(1, 20)
  }
});

export const Box = emotion.div(
  {
    ...flex.vertical,
    position: 'relative',
    maxHeight: 260
  },
  ({ isMobile }) => ({
    ...(isMobile && {
      borderRadius: 4,
      overflow: 'hidden'
    }),
    ...(!isMobile && {
      cursor: 'pointer'
    })
  })
);

export const Image = emotion(GraphCmsImage)({
  objectFit: 'cover',
  width: '100%',
  height: '100%'
});
