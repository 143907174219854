import emotion from '@emotion/styled';
import flex from 'styles/flex';
import colors from 'styles/colors';
import { queries } from 'styles/responsive';

export const ReactModule = emotion.div({
  ...flex.horizontal,
  borderRadius: 3,
  backgroundColor: 'white',
  boxShadow: '1px 0px 4px #cacaca',
  margin: 'auto',
  padding: 25,
  width: '100%',
  maxWidth: 800,
  position: 'relative',
  [queries.mediumWidth]: {
    ...flex.vertical
  }
});

const borderStyle = '1px solid #e6e6e6';
const spacing = 30;

export const LeftSection = emotion.div({
  ...flex.vertical,
  borderRight: borderStyle,
  paddingRight: spacing,
  flex: 1,
  [queries.mediumWidth]: {
    paddingRight: 0,
    paddingBottom: spacing,
    borderRight: 'none',
    borderBottom: borderStyle
  }
});

export const Logo = emotion.img({
  maxWidth: 200,
  marginBottom: 10,
  margin: 'auto'
});

export const Text = emotion.div({
  color: colors.textColor,
  fontSize: 17,
  textAlign: 'center',
  lineHeight: '27px'
});

export const RightSection = emotion.div({
  ...flex.vertical,
  ...flex.centerVertical,
  flex: 1,
  paddingLeft: 30,
  [queries.mediumWidth]: {
    paddingLeft: 0,
    paddingTop: spacing
  }
});

export const List = emotion.ul({
  width: '100%',
  color: colors.textColor,
  '& li': {
    margin: '10px 0'
  }
});
