import React, { Component } from 'react';
import { transform } from 'utils/image-utils';

//glamorous
import {
  MainSection,
  Subtitle,
  Overlay,
  LazyBackground,
  GradientOverlay,
  Content
} from './styles';

//components
import ScrollArrow from 'components/ScrollArrow';
import Logo from 'components/SvgLogo/index';

import { sections } from 'styles/config';
import { sequence } from 'utils/time-utils';
const { main } = sections;

import { ANIMATION_STEPS } from 'config/animation-steps';

const addAnimation = name => () => document.documentElement.classList.add(name);

class MainSectionComponent extends Component {
  state = {
    innerWidth: null
  };

  componentDidMount() {
    this.setState({
      innerWidth: window.innerWidth,
      isLandscape: window.matchMedia('(orientation: landscape)').matches
    });

    sequence([
      1000,
      addAnimation(ANIMATION_STEPS.ANIMATE_CONTENT),
      1000,
      addAnimation(ANIMATION_STEPS.MOVE_TEXT),
      500,
      addAnimation(ANIMATION_STEPS.ANIMATE_BRACKETS)
    ]);
  }

  render() {
    const { innerWidth, isLandscape } = this.state;
    const { collapsed, settings } = this.props;
    const { backgroundImage, backgroundImageMobile } = settings;

    return (
      <MainSection collapsed={collapsed}>
        <Overlay>
          <GradientOverlay {...main.background} />
          {!collapsed &&
            innerWidth && (
              <LazyBackground
                loadTemp={false}
                extraTransforms={[
                  transform('quality', { value: 50 }),
                  'compress'
                ]}
                resizeConfig={{ w: innerWidth }}
                blurAmount={2}
                addPlaceholder={false}
                inView={true}
                logo={isLandscape ? backgroundImage : backgroundImageMobile}
              />
            )}
        </Overlay>

        <Content>
          <Logo />
          <Subtitle>React and JavaScript workshops</Subtitle>
          <ScrollArrow />
        </Content>
      </MainSection>
    );
  }
}

export default MainSectionComponent;
