import React, { Component } from "react";

//glamorous
import { WorkshopTypesSection, WorkshopTypes } from "./styles";
import { Wrapper, Title, Subtitle } from "styles/shared-components";

//animation
import WorkshopType from "components/WorkshopType";

//icons
import publicIcon from "images/svg-icons/public.svg";
import companyIcon from "images/svg-icons/company.svg";
import conferenceIcon from "images/svg-icons/conference.svg";

//animations
import { Observer, Enter } from "react-genie";
import { animations } from "styles/animations";

class WorkshopTypesSectionComponent extends Component {
  render() {
    return (
      <WorkshopTypesSection>
        <Observer triggerOnce={true}>
          {({ inView, ref }) => (
            <Wrapper ref={ref}>
              <Title centered> What is it? </Title>
              <Subtitle extraSpace>
                We offer private and public on-site workshops all around the
                world.
              </Subtitle>

              <WorkshopTypes>
                <Enter
                  visible={inView}
                  delay={200}
                  duration={500}
                  animationName={animations.fadeInUp}
                >
                  <WorkshopType
                    iconBackgroundColor="#b4ddf3"
                    inView={inView}
                    icon={publicIcon}
                    title="Public Workshops"
                    description="Are you interested in hosting a public workshop that can be attended by anyone?
You can pick the place, decide on the number of attendees, price of the tickets, etc.
The only thing that you should care about is the marketing of the event. We’ll bring the knowledge and the materials!"
                  />
                </Enter>
                <Enter
                  visible={inView}
                  delay={400}
                  duration={500}
                  animationName={animations.fadeInUp}
                >
                  <WorkshopType
                    iconBackgroundColor="#facb9c"
                    inView={inView}
                    icon={companyIcon}
                    title="Company Training"
                    description="Want to take your company's expertise to the next level?
                  Don't waste precious time and money by aimlessly combing the internet for resources.
                  With the Company Training you can tailor the course material specifically to the topics that matter to your business.
                  All of the attendees will get the complete and undivided attention of the trainer."
                  />
                </Enter>
                <Enter
                  visible={inView}
                  delay={600}
                  duration={500}
                  animationName={animations.fadeInUp}
                >
                  <WorkshopType
                    iconBackgroundColor="#6899c9"
                    inView={inView}
                    icon={conferenceIcon}
                    title="Conference Workshops"
                    description="Let's be real here, attending a conference sounds way more attractive to people if it also includes a workshop.
                  If you want to spice up your conference, the Conference Workshop is for you. Choose the topics that align with what the conference is about, and let's rock!"
                  />
                </Enter>
              </WorkshopTypes>
            </Wrapper>
          )}
        </Observer>
      </WorkshopTypesSection>
    );
  }
}

export default WorkshopTypesSectionComponent;
